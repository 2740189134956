import { useIntl } from "react-intl";
import React from "react";
import FeatureCard from "../FeatureCard.js";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TripNoteText from "./TrimNoteText.js";
import TripDurationText from "./TripDurationText.js";

export default function TripDetailsCard({ state }) {
  const { trip } = state;
  const { formatNumber } = useIntl();

  return (
    <CardContent>
      <Grid container columnSpacing={1} rowSpacing={0}>
        <Grid item xs={6}>
          <Card elevation={0}>
            <Box sx={{ paddingTop: 2 }}>
              <Typography component="div" variant="h5" gutterBottom>
                {trip.title}
              </Typography>
              <TripDurationText trip={trip} />
              <TripNoteText note={trip.note} />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <FeatureCard title="Total Nights" value={formatNumber(trip.totalNights || 0)} variant="outlined" />
        </Grid>
        <Grid item xs={2}>
          <FeatureCard
            title="Payments Due"
            value={formatNumber(trip.totalPricePaidUnpaid, { style: "currency", currency: "GBP" })}
            variant="outlined"
            enablePrivacy
          />
        </Grid>
        <Grid item xs={2}>
          <FeatureCard
            title="Total Paid"
            enablePrivacy
            value={formatNumber(trip.totalPricePaid, { style: "currency", currency: "GBP" })}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
}
