import React from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import GenericSegmentsTable from "./GenericSegmentsTable.js";

export default function FlightsList({ carrierId, linkState, loyaltyProgramId, showOnlySource }) {
  const segments = useLiveQuery(() => {
    if (loyaltyProgramId) {
      return db.segments.where("loyaltyProgramIds").equals(loyaltyProgramId).reverse().sortBy("departureDate");
    } else if (carrierId) {
      return db.flights.where("carrier.id").equals(carrierId).reverse().sortBy("departureDate");
    } else {
      return db.flights.reverse().sortBy("departureDate");
    }
  }, [carrierId, loyaltyProgramId]);

  return (
    <GenericSegmentsTable
      segments={segments}
      isLoading={segments === undefined}
      linkState={linkState}
      showOnlySource={showOnlySource}
    />
  );
}
