import Checkbox from "@mui/material/Checkbox";
import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
/**
 * @param {boolean} currentValue
 * @return {boolean|null}
 */
function handleIndeterminateCheckboxValue(currentValue) {
  // Checked state cycles from: false -> null -> true -> ...

  if (currentValue === false) {
    return null;
  } else return currentValue !== true;
}

export default function EditorRowActionsButton({ value, set, onDelete, isStay, isSegment }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const accountMenuOpen = Boolean(anchorEl);

  const { isSkipped, id, isSecondaryPassenger, isBreakfastIncluded, hasFitnessCenter } = value;
  const isSkippedSafe = isSkipped || false;
  const isSecondaryPassengerSafe = isSecondaryPassenger || false;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onToggleIsSkipped = () => {
    set(id, {
      isSkipped: !isSkippedSafe,
    });
  };

  const onToggleIsSecondaryPassenger = () => {
    set(id, {
      isSecondaryPassenger: !isSecondaryPassengerSafe,
    });
  };

  const onToggleIsBreakfastIncluded = () => {
    set(id, {
      isBreakfastIncluded: handleIndeterminateCheckboxValue(isBreakfastIncluded),
    });
  };

  const onToggleHasFitnessCenter = () => {
    set(id, {
      hasFitnessCenter: handleIndeterminateCheckboxValue(hasFitnessCenter),
    });
  };

  const onDeleteClick = () => {
    handleClose();
    onDelete();
  };

  let breakfastSubtext = "Unknown";

  if (isBreakfastIncluded === true) {
    breakfastSubtext = "Included";
  } else if (isBreakfastIncluded === false) {
    breakfastSubtext = "Not included";
  }

  let fitnessCenterSubtext = "Unknown";

  if (hasFitnessCenter === true) {
    fitnessCenterSubtext = "Included";
  } else if (hasFitnessCenter === false) {
    fitnessCenterSubtext = "Not included";
  }

  return (
    <>
      <IconButton onClick={handleMenu} color="inherit" size="small">
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={accountMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          dense: true,
        }}
      >
        <MenuItem disableTouchRipple selected={isSkippedSafe} onClick={onToggleIsSkipped}>
          <ListItemIcon>
            <Checkbox
              sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}
              checked={isSkippedSafe}
              size="small"
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary="Skipped" />
        </MenuItem>

        <MenuItem disableTouchRipple selected={isSecondaryPassengerSafe} onClick={onToggleIsSecondaryPassenger}>
          <ListItemIcon>
            <Checkbox
              sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}
              checked={isSecondaryPassengerSafe}
              size="small"
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary="Secondary Pax." />
        </MenuItem>

        {isStay && (
          <MenuItem disableTouchRipple selected={isBreakfastIncluded === true} onClick={onToggleIsBreakfastIncluded}>
            <ListItemIcon>
              <Checkbox
                sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}
                checked={isBreakfastIncluded === true}
                indeterminate={isBreakfastIncluded !== true && isBreakfastIncluded !== false}
                size="small"
                disableRipple
                checkedIcon={<CheckCircleIcon color="success" />}
                icon={<CancelIcon color="error" />}
                indeterminateIcon={<HelpOutlineIcon color="secondary" />}
              />
            </ListItemIcon>
            <ListItemText primary="Includes Breakfast" secondary={breakfastSubtext} />
          </MenuItem>
        )}

        {isStay && (
          <MenuItem disableTouchRipple selected={hasFitnessCenter === true} onClick={onToggleHasFitnessCenter}>
            <ListItemIcon>
              <Checkbox
                sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}
                checked={hasFitnessCenter === true}
                indeterminate={hasFitnessCenter !== true && hasFitnessCenter !== false}
                size="small"
                disableRipple
                checkedIcon={<CheckCircleIcon color="success" />}
                icon={<CancelIcon color="error" />}
                indeterminateIcon={<HelpOutlineIcon color="secondary" />}
              />
            </ListItemIcon>
            <ListItemText primary="Fitness Center" secondary={fitnessCenterSubtext} />
          </MenuItem>
        )}

        <Divider />

        <MenuItem disableTouchRipple onClick={onDeleteClick}>
          <ListItemIcon>
            <DeleteForeverIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: "error" }} primary="Delete" />
        </MenuItem>
      </Menu>
    </>
  );
}
