import Divider from "@mui/material/Divider";
import React from "react";
import SubScrollLayout from "../SubScrollLayout.js";
import BookingsTable from "./BookingsTable.js";
import ItemsByDateTable from "./ItemsByDateTable.js";
import ItemsByLoyaltyTable from "./ItemsByLoyaltyTable.js";
import TripDetailsMap from "./TripDetailsMap.js";
// import TripDetailsViewTabs from "./TripDetailsViewTabs.js";

export default function TripDetailsMain({ state, tab, dispatch }) {
  const {
    bookingsOrderedByOptions,
    groupBookingsByLoyaltyProgram,
    bookingsOrderedByDate,
    flights,
    sortBy,
    sortOrder,
    hideCancelledBookings,
  } = state;

  const isMap = tab === "map";

  const onRequestSort = (event, property) => {
    const isAsc = state.sortBy === property && state.sortOrder === "asc";

    dispatch({
      type: "SET_SORT",
      value: {
        sortBy: property,
        sortOrder: isAsc ? "desc" : "asc",
      },
    });
  };

  const onToggleHideCancelledBookings = (newValue) => {
    dispatch({
      type: "SET_FILTER",
      value: {
        hideCancelledBookings: newValue,
      },
    });
  };

  return (
    <>
      {/*<Box>*/}
      {/*  <Divider />*/}
      {/*  <TripDetailsViewTabs tab={tab} tripId={state.trip.id} />*/}
      {/*  <Divider />*/}
      {/*</Box>*/}
      <Divider />
      {isMap && <TripDetailsMap flights={flights} />}
      {!isMap && (
        <SubScrollLayout key={tab}>
          {tab === "bookings" && (
            <BookingsTable
              bookings={bookingsOrderedByOptions}
              sortBy={sortBy}
              sortOrder={sortOrder}
              onRequestSort={onRequestSort}
              hideCancelledBookings={hideCancelledBookings}
              onToggleHideCancelledBookings={onToggleHideCancelledBookings}
            />
          )}
          {tab === "loyalty" && <ItemsByLoyaltyTable groupBookingsByLoyaltyProgram={groupBookingsByLoyaltyProgram} />}
          {tab === "itinerary" && <ItemsByDateTable bookingsOrderedByDate={bookingsOrderedByDate} />}
        </SubScrollLayout>
      )}
    </>
  );
}
