import Joi from "joi";
import { isBefore, isAfter, differenceInCalendarDays } from "date-fns";
import Big from "big.js";
import { CANCELLED, REFUNDED, BOOKED } from "./BookingConstants.js";

export const TripValidator = Joi.object({
  title: Joi.string().required(),
  id: Joi.string().optional(),
  note: Joi.string().optional(),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  startDate: Joi.date().optional(),
  sortDate: Joi.date().required(),
  endDate: Joi.date().optional(),
  totalPricePaid: Joi.number().min(0).optional().precision(2),
  totalPricePaidUnpaid: Joi.number().min(0).optional().precision(2),
  totalPricePaidForTravel: Joi.number().min(0).required().precision(2),
  totalPricePaidForServices: Joi.number().min(0).required().precision(2),
  totalPricePaidForStays: Joi.number().min(0).required().precision(2),
  totalNights: Joi.number().min(0).required(),
  durationInDays: Joi.number().min(0).required(),
  totalPrice: Joi.array().items(
    Joi.object({
      currency: Joi.string().required(),
      totalPricePaid: Joi.number().min(0).required().precision(2),
    }),
  ),
}).label("Trip");

/**
 * Represents a Trip, this can contain one or more `Bookings`.
 */
export default class Trip {
  constructor() {
    this.totalPricePaid = 0.0;
    this.totalPrice = [];
    this.totalNights = 0;
    this.durationInDays = 0;
    this.totalPricePaidForTravel = 0.0;
    this.totalPricePaidForServices = 0.0;
    this.totalPricePaidForStays = 0.0;
    this.totalPricePaidUnpaid = 0.0;
  }

  /**
   * The title of this Trip.
   * @type {String}
   */
  title;

  /**
   * The note about this trip, if anything.
   * @type {String}
   */
  note;

  /**
   * The creation date.
   * @type {Date}
   */
  createdAt;

  /**
   * The sortable date
   * @type {Date}
   */
  sortDate;

  /**
   * The update date.
   * @type {Date}
   */
  updatedAt;

  /**
   * The date of the first relevant date
   * @type {Date|undefined}
   */
  startDate;

  /**
   * The date of the last relevant date
   * @type {Date|undefined}
   */
  endDate;

  /**
   * The Trip ID
   * @type {String}
   */
  id;

  /**
   * The total price actually paid on card in home currency. Calculated.
   * @type {Number}
   */
  totalPricePaid;

  /**
   * Outstanding total price actually due to be paid on card in home currency. Calculated on bookings not marked as paid.
   * @type {Number}
   */
  totalPricePaidUnpaid;
  /**
   * The total price actually paid on card in home currency on Stays. Calculated.
   * @type {Number}
   */
  totalPricePaidForStays;

  /**
   * The total price actually paid on card in home currency on Travel. Calculated.
   * @type {Number}
   */
  totalPricePaidForTravel;

  /**
   * The total price actually paid on card in home currency on Services. Calculated.
   * @type {Number}
   */
  totalPricePaidForServices;

  /**
   * The total price in various supplier currencies in the trip. Calculated.
   * @type {{ price: Number, currency: String }[]}
   */
  totalPrice;

  /**
   * Total nights on Stays.
   * @type {Number}
   */
  totalNights;

  /**
   * Total calendar day duration. Regardless of booked stays.
   * @type {Number}
   */
  durationInDays;

  /**
   *
   * @param {Booking[]} bookings
   */
  calculateTotals({ bookings }) {
    const result = bookings.reduce(
      (acc, booking) => {
        if (booking.startDate) {
          if (!acc.startDateIncludingCancelled || isBefore(booking.startDate, acc.startDateIncludingCancelled)) {
            acc.startDateIncludingCancelled = booking.startDate;
            if (booking.status === BOOKED) acc.startDate = booking.startDate;
          }
        }

        if (booking.endDate) {
          if (!acc.endDateIncludingCancelled || isAfter(booking.endDate, acc.endDateIncludingCancelled)) {
            acc.endDateIncludingCancelled = booking.endDate;
            if (booking.status === BOOKED) acc.endDate = booking.endDate;
          }
        }

        if (booking.status === REFUNDED) {
          // Refunded bookings are basically ignored as if they never happened.
          return acc;
        }
        if (booking.status === CANCELLED && !booking.isPaid) {
          // Cancelled bookings that were never paid are basically ignored as if they never happened.
          return acc;
        }
        const pricePaidSafe = booking.pricePaid || 0.0;
        acc.totalPricePaid = acc.totalPricePaid.plus(pricePaidSafe);
        acc.totalNights += booking._nights || 0;
        if (!booking.isPaid) {
          acc.totalPricePaidUnpaid = acc.totalPricePaidUnpaid.plus(pricePaidSafe);
        }
        const isOneType = booking.type.length === 1;

        if (isOneType) {
          if (booking.type[0] === "SERVICE") {
            acc.totalPricePaidForServices = acc.totalPricePaidForServices.plus(pricePaidSafe);
          } else if (booking.type[0] === "STAY") {
            acc.totalPricePaidForStays = acc.totalPricePaidForStays.plus(pricePaidSafe);
          } else if (booking.type[0] === "TRAVEL") {
            acc.totalPricePaidForTravel = acc.totalPricePaidForTravel.plus(pricePaidSafe);
          }
        }

        if (booking.pricePaid > 0 && booking.pricePaid) {
          // TODO: Add totalPrice
        }

        return acc;
      },
      {
        totalPricePaid: new Big(0.0),
        totalPricePaidUnpaid: new Big(0.0),
        totalPrice: [],
        totalNights: 0,
        totalPricePaidForServices: new Big(0.0),
        totalPricePaidForStays: new Big(0.0),
        totalPricePaidForTravel: new Big(0.0),
        startDate: undefined,
        endDate: undefined,
        startDateIncludingCancelled: undefined,
        endDateIncludingCancelled: undefined,
      },
    );
    this.totalPricePaid = result.totalPricePaid.toNumber();
    this.totalPricePaidUnpaid = result.totalPricePaidUnpaid.toNumber();
    this.totalPrice = result.totalPrice;
    this.totalNights = result.totalNights;
    this.durationInDays = result.durationInDays;
    this.totalPricePaidForServices = result.totalPricePaidForServices.toNumber();
    this.totalPricePaidForStays = result.totalPricePaidForStays.toNumber();
    this.totalPricePaidForTravel = result.totalPricePaidForTravel.toNumber();
    this.updatedAt = new Date();
    this.startDate = result.startDate;
    this.endDate = result.endDate;
    this.sortDate = result.startDate || this.createdAt;

    if (!this.startDate && !this.endDate) {
      this.startDate = result.startDateIncludingCancelled;
      this.endDate = result.endDateIncludingCancelled;
    }

    if (!this.startDate || !this.endDate) {
      this.durationInDays = 0;
    } else {
      this.durationInDays = differenceInCalendarDays(this.endDate, this.startDate) + 1;
    }
  }

  /**
   * Indicates if this Trip and its data is valid.
   * @return {boolean}
   */
  get isValid() {
    const { error, value } = TripValidator.validate(this);
    if (error === undefined) {
      return true;
    }
    console.log(error, value);
    return false;
  }
}
