import React from "react";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import usePrivacy from "../../contexts/Privacy/usePrivacy.js";

export default function PrivacyButton() {
  const { privacyModeEnabled, setPrivacyModeEnabled } = usePrivacy();
  const icon = !privacyModeEnabled ? <VisibilityIcon /> : <VisibilityOffIcon />;
  const toggle = () => {
    setPrivacyModeEnabled(!privacyModeEnabled);
  };
  return (
    <IconButton color={privacyModeEnabled ? "primary" : "inherit"} size="small" onClick={toggle}>
      {icon}
    </IconButton>
  );
}
