import { formatCurrencyName } from "../../lib/formatCurrency.js";

function generateCurrencyItem(id) {
  return { id, title: formatCurrencyName(id) };
}

export function sortCurrencyByTitle(a, b) {
  return a.title.localeCompare(b.title, "en", { sensitivity: "base" });
}

export function generateCurrencyFromRates(rates) {
  return Object.entries(rates)
    .map(([key]) => {
      return generateCurrencyItem(key);
    })
    .sort(sortCurrencyByTitle);
}

const currency = [
  generateCurrencyItem("AUD"),
  generateCurrencyItem("USD"),
  generateCurrencyItem("BRL"),
  generateCurrencyItem("EUR"),
  generateCurrencyItem("GBP"),
  generateCurrencyItem("CHF"),
  generateCurrencyItem("ILS"),
  generateCurrencyItem("NOK"),
  generateCurrencyItem("DKK"),
  generateCurrencyItem("SGD"),
  generateCurrencyItem("MYR"),
  generateCurrencyItem("CLP"),
].sort(sortCurrencyByTitle);

export default currency;
