import React from "react";

const CurrencyContext = React.createContext({
  currency: [],
  loadCurrencyRateData: async () => {},
  convertCurrency: () => {},
  dataLoaded: null,
});

export default CurrencyContext;
