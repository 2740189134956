import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import React from "react";
import useCurrency from "../../contexts/Currency/useCurrency.js";

export default function RefreshCurrencyButton() {
  const { loadCurrencyRateData } = useCurrency();

  const onClick = async () => {
    await loadCurrencyRateData();
  };
  return (
    <>
      <Button startIcon={<AddIcon fontSize="small" />} color="secondary" variant="outlined" onClick={onClick}>
        Refresh
      </Button>
    </>
  );
}
