import BookingEditorReducerGeneral from "./BookingEditorReducerGeneral.js";
import cleanDateValue from "./cleanDateValue.js";
import { TRAVEL, STAY } from "../../models/BookingConstants.js";
import SegmentEditorReducerValidator from "./SegmentEditorReducerValidator.js";
import StayEditorReducerValidator from "./StayEditorReducerValidator.js";
import emptyTravelObject from "./emptyTravelObject.js";
import emptyStayObject from "./emptyStayObject.js";
import { isAfter, addMonths, subYears, addYears } from "date-fns";

function determineRelevantDate({ trip, bookings }) {
  let bookingDate;
  let departureDate;
  let checkOutDate;

  if (bookings) {
    bookings.forEach((booking) => {
      if (booking.bookingDate) {
        if (!bookingDate || isAfter(booking.bookingDate, bookingDate)) {
          bookingDate = booking.bookingDate;
        }
      }

      booking.stays?.forEach((stay) => {
        if (stay.checkOutDate) {
          if (!checkOutDate || isAfter(stay.checkOutDate, checkOutDate)) {
            checkOutDate = stay.checkOutDate;
          }
        }
      });

      booking.segments?.forEach((segment) => {
        if (segment.departureDate) {
          if (!departureDate || isAfter(segment.departureDate, departureDate)) {
            departureDate = segment.departureDate;
          }
        }
      });
    });
  }

  const now = trip?.startDate || new Date();

  return {
    bookingDate: cleanDateValue(bookingDate || now),
    departureDate: cleanDateValue(departureDate || now),
    checkOutDate: cleanDateValue(checkOutDate || now),
  };
}

export default function BookingEditorInitialState({
  type,
  isEditing,
  existingData,
  trip,
  bookings,
  agent,
  carrier,
  source,
}) {
  const { booking } = existingData || {};
  const { segments, stays } = booking || {};
  const id = booking?.id || self.crypto.randomUUID();
  const now = new Date();
  const fallbackTripId = trip || null;
  const fallbackAgent = agent || null;
  const migratedTrip = booking && booking.trip ? { id: booking.trip.id, title: booking.trip.title } : fallbackTripId;
  const suggestedDates = determineRelevantDate({ trip, bookings });

  const tripPath = migratedTrip ? `/trips/${migratedTrip.id}` : undefined;

  const fallbackCancelPath = () => {
    if (source === "flights") {
      return `/flights`;
    }

    if (source === "agent" && agent) {
      return `/agents/${agent.id}`;
    }

    if (source === "agent" && booking?.agent) {
      return `/agents/${booking.agent.id}`;
    }

    if (source === "carrier" && booking?.leadingCarrier) {
      return `/carriers/${booking.leadingCarrier.id}`;
    }

    if (source === "carrier") {
      return `/carriers`;
    }

    if (source === "agent") {
      return `/agents`;
    }

    if (source === "trip.loyalty") {
      return `${tripPath}/loyalty`;
    }

    if (source === "trip.itinerary") {
      return `${tripPath}/itinerary`;
    }

    if (migratedTrip) {
      return `/trips/${migratedTrip.id}`;
    }
    return "/";
  };

  const state = {
    id,
    isEditing: isEditing === true,
    bookingReference: booking?.bookingReference || "",
    pointsPaidAsString: `${booking?.pointsPaid?.toString() ?? ""}`,
    pointsPaidIsValid: true,
    pointsPaid: booking?.pointsPaid ?? undefined,
    pricePaidAsString: `${booking?.pricePaid?.toString() ?? ""}`,
    pricePaidIsValid: true,
    pricePaid: booking?.pricePaid ?? undefined,
    priceAsString: `${booking?.price?.toString() ?? ""}`,
    priceIsValid: true,
    price: booking?.price ?? undefined,
    priceCurrency: booking?.priceCurrency || "GBP",
    createdAt: booking?.createdAt || now,
    updatedAt: booking?.updatedAt || now,
    bookingDate: booking?.bookingDate || suggestedDates.bookingDate,
    cancelBeforeDate: booking?.cancelBeforeDate || null,
    bookingDateMaxValue: addMonths(now, 1),
    bookingDateMinValue: subYears(now, 20),
    endDateMaxValue: addYears(now, 4),
    suggestedDepartureDate: suggestedDates.departureDate,
    suggestedCheckInDate: suggestedDates.checkOutDate,
    status: booking?.status || "BOOKED",
    isPaid: booking?.isPaid ?? false,
    isPriceEstimated: booking?.isPriceEstimated ?? true,
    agent: booking?.agent || fallbackAgent,
    canChangeAgent: agent === undefined || agent === null,
    paymentMethod: booking?.paymentMethod || null,
    trip: migratedTrip,
    tripRequired: Boolean(booking?.trip?.id?.length > 0),
    segments: [],
    segmentsToDelete: [],
    stays: [],
    staysToDelete: [],
    isValid: false,
    type,
    canAddSegments: true, // fallbackType === TRAVEL,
    canAddStays: true, // fallbackType === STAY,
    note: booking?.note || "",
    disableTripSelection: Boolean(trip),
    disableTypeSelection: Boolean(type),
    cancelPath: fallbackCancelPath(),
    tripPath,
  };

  if (!isEditing) {
    // Create new empty elements
    if (type === TRAVEL) {
      state.segments.push(emptyTravelObject({ bookingId: state.id, isLeadingCarrier: true, carrier }));
    } else if (type === STAY) {
      state.stays.push(emptyStayObject({ bookingId: state.id }));
    }
  } else {
    // Restore Travel Segments
    const emptyTravel = emptyTravelObject({ bookingId: state.id });
    state.segments = (segments || []).map((segment) => {
      // map here?
      const result = {
        ...segment,
        originTitle: emptyTravel.originTitle,
        destinationTitle: emptyTravel.destinationTitle,
        carrierReference: segment.carrierReference || emptyTravel.carrierReference,
        flightNumber: segment.flightNumber || emptyTravel.flightNumber,
        eTicket: segment.eTicket || emptyTravel.eTicket,
        loyaltyProgramIds: segment.loyaltyProgramIds || emptyTravel.loyaltyProgramIds,
        loyaltyProgramActivities: segment.loyaltyProgramActivities || emptyTravel.loyaltyProgramActivities,
      };

      // 'null' is required for select types as 'undefined' is not acceptable empty value
      result.carrier = segment.carrier || null;
      result.destination = segment.destination || null;
      result.origin = segment.origin || null;

      return SegmentEditorReducerValidator(result);
    });

    if (state.segments.length && !state.segments.find((segment) => segment.isLeadingCarrier)) {
      // auto-select a leading carrier
      state.segments[0] = { ...state.segments[0], isLeadingCarrier: true };
    }

    // Restore Stay Objects
    const emptyStay = emptyStayObject({ bookingId: state.id, tripId: state.trip?.id });
    state.stays = (stays || []).map((stay) => {
      const result = {
        ...stay,
        bookingReference: stay.bookingReference || emptyStay.bookingReference,
        roomNumber: stay.roomNumber || emptyStay.roomNumber,
        roomName: stay.roomName || emptyStay.roomName,
        note: stay.note || emptyStay.note,
        loyaltyProgramIds: stay.loyaltyProgramIds || emptyStay.loyaltyProgramIds,
        loyaltyProgramActivities: stay.loyaltyProgramActivities || emptyStay.loyaltyProgramActivities,
        checkOutDate: cleanDateValue(stay.checkOutDate), // should highlight any bad dates
      };

      result.brand = stay.brand || null;
      result.location = stay.location || null;

      return StayEditorReducerValidator(result);
    });

    return BookingEditorReducerGeneral(state);
  }

  return state;
}
