import React from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import GenericStaysTable from "../GenericStaysTable/GenericStaysTable.js";

export default function LoyaltyProgramActivityStaysList({ loyaltyProgramId, linkState }) {
  const stays = useLiveQuery(() =>
    db.stays.where("loyaltyProgramIds").equals(loyaltyProgramId).reverse().sortBy("checkInDate"),
  );

  return (
    <GenericStaysTable stays={stays} showTrip linkState={linkState} isLoading={stays === undefined} showOnlySource />
  );
}
