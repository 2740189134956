import Button from "@mui/material/Button";
import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link as ReactRouterLink } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import FlightIcon from "@mui/icons-material/Flight";
import HotelIcon from "@mui/icons-material/Hotel";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import { useLocation } from "react-router-dom";
import AppUpdateButton from "./AppUpdateButton.js";
import AuxiliaryMenuButton from "./AuxiliaryMenuButton.js";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import GlobalButton from "./GlobalButton.js";
import PrivacyButton from "./PrivacyButton.js";
export default function AppHeaderBar() {
  const { pathname } = useLocation();

  const knownValues = ["/", "/hotels", "/trips", "/flights"];
  const isKnown = knownValues.includes(pathname);
  return (
    <AppBar
      position="fixed"
      color="default"
      sx={{
        backgroundImage: "none",
        borderBottomColor: "divider",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        // For the PWA Window
        appRegion: "drag",
        WebkitAppRegion: "drag",
        paddingTop: "env(titlebar-area-height, 0px)",
      }}
      elevation={0}
    >
      <Toolbar variant="dense" disableGutters sx={{ minHeight: (theme) => theme.mixins.toolbar.minHeight }}>
        <Tabs value={isKnown ? pathname : false}>
          <Tab
            component={ReactRouterLink}
            to="/"
            icon={<FlightIcon fontSize="small" />}
            iconPosition="start"
            label="Travel"
            value="/"
            disableRipple
          />
          <Tab
            component={ReactRouterLink}
            to="/hotels"
            icon={<HotelIcon fontSize="small" />}
            iconPosition="start"
            label="Hotels"
            value="/hotels"
            disableRipple
          />
          <Tab
            component={ReactRouterLink}
            to="/trips"
            icon={<ModeOfTravelIcon fontSize="small" />}
            iconPosition="start"
            label="Trips"
            value="/trips"
            disableRipple
          />
          <Tab
            component={ReactRouterLink}
            to="/flights"
            icon={<ConnectingAirportsIcon fontSize="small" />}
            iconPosition="start"
            label="Flights"
            value="/flights"
            disableRipple
          />
        </Tabs>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" spacing={1} marginRight={2}>
          <Button
            size="small"
            to={`/bookings/new/travel`}
            component={ReactRouterLink}
            color="inherit"
            startIcon={<AddCircleOutlineIcon fontSize="small" />}
          >
            Add Travel
          </Button>

          <Button
            size="small"
            to={`/bookings/new/hotel`}
            component={ReactRouterLink}
            color="inherit"
            startIcon={<AddCircleOutlineIcon fontSize="small" />}
          >
            Add Hotel
          </Button>

          {/*<IconButton size="small" to={`/bookings/new/travel`} component={ReactRouterLink} color="inherit">*/}
          {/*  <AddCircleOutlineIcon fontSize="small" />*/}
          {/*</IconButton>*/}
          {/*<IconButton size="small" to="/carriers" component={ReactRouterLink} color="inherit">*/}
          {/*  <AirlinesIcon fontSize="small" />*/}
          {/*</IconButton>*/}
          <AppUpdateButton />
          <PrivacyButton />
          <GlobalButton />
          <AuxiliaryMenuButton />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
