import activeFlightsOnly from "./activeFlightsOnly.js";
import bookingsOrderedByDate, { bookingsOrderedByOptions } from "./bookingsOrderedByDate.js";
import groupBookingsByLoyaltyProgram from "./groupBookingsByLoyaltyProgram.js";

export default function TripDetailsManagerReducer(state, action) {
  const { type, value } = action;
  if (type === "SET_STATE") {
    return { ...state, ...value };
  }

  if (type === "SET_BOOKINGS") {
    return {
      ...state,
      bookings: value,
      flights: activeFlightsOnly(value),
      groupBookingsByLoyaltyProgram: groupBookingsByLoyaltyProgram({ bookings: value }),
      bookingsOrderedByDate: bookingsOrderedByDate({ bookings: value }),
      bookingsOrderedByOptions: bookingsOrderedByOptions({
        bookings: value,
        sortBy: state.sortBy,
        sortOrder: state.sortOrder,
        hideCancelledBookings: state.hideCancelledBookings,
      }),
    };
  }

  if (type === "SET_SORT") {
    return {
      ...state,
      sortBy: action.value.sortBy,
      sortOrder: action.value.sortOrder,
      bookingsOrderedByOptions: bookingsOrderedByOptions({
        bookings: state.bookings,
        sortBy: action.value.sortBy,
        sortOrder: action.value.sortOrder,
        hideCancelledBookings: state.hideCancelledBookings,
      }),
    };
  }

  if (type === "SET_FILTER") {
    return {
      ...state,
      hideCancelledBookings: action.value.hideCancelledBookings,
      bookingsOrderedByOptions: bookingsOrderedByOptions({
        bookings: state.bookings,
        sortBy: state.sortBy,
        sortOrder: state.sortOrder,
        hideCancelledBookings: action.value.hideCancelledBookings,
      }),
    };
  }
  return state;
}
