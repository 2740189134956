import { addDays, differenceInCalendarDays } from "date-fns";
import { BOOKED, STAY, TRAVEL, CANCELLED } from "../../models/BookingConstants.js";

/**
 * Sort items in order by their relevant date
 * @param {{ startDate: Date, endDate: Date }} a - First item
 * @param {{ startDate: Date, endDate: Date }} b - Second item
 * @return {number}
 */
function sortItemsByRelevantDates(a, b) {
  // A negative value indicates that a should come before b.
  // A positive value indicates that a should come after b.
  // Zero or NaN indicates that a and b are considered equal.
  const dateComparison = a.startDate.getTime() - b.startDate.getTime();
  if (dateComparison !== 0) return dateComparison;

  return (a.endDate?.getTime() || 0) - (b.endDate?.getTime() || 0);
}

export default function bookingsOrderedByDate({ bookings }) {
  const result = bookings
    .map((booking) => {
      return [
        ...(booking.segments || []).map((item) => ({
          item,
          booking,
          type: TRAVEL,
        })),
        ...(booking.stays || []).map((item) => ({
          item,
          booking,
          type: STAY,
        })),
      ];
    })
    .flat()
    .map((item) => {
      // Don't show skipped or cancelled bookings in the itinerary
      if (item.item.isSkipped || item.booking.status !== BOOKED) return null;
      const relevantStartDate = item.item.departureDate || item.item.checkInDate;
      let relevantEndDate = item.item.checkOutDate || null;

      if (item.type === TRAVEL && item.item.departureDate) {
        relevantEndDate = item.item.isOvernight ? addDays(item.item.departureDate, 1) : item.item.departureDate;
      }

      if (item.type === STAY && !relevantEndDate) {
        relevantEndDate = relevantStartDate;
      }

      const startDate = relevantStartDate || item.booking.bookingDate;
      // const endDate = item.type === STAY ? relevantEndDate || item.booking.bookingDate : null;
      return { ...item, startDate, endDate: relevantEndDate };
    })
    .filter((item) => item !== null)
    .sort(sortItemsByRelevantDates);

  return result.map((item, index, items) => {
    if (index === 0) {
      return { ...item, nights: 0 };
    }

    const previousItem = items[index - 1];

    const previousDate = previousItem.endDate || previousItem.startDate;

    return {
      ...item,
      previousDate,
      nights: differenceInCalendarDays(item.startDate, previousDate),
    };
  });
}

export function bookingsOrderedByOptions({ bookings, sortBy, sortOrder, hideCancelledBookings }) {
  // ASCENDING - UP ARROW - smallest to largest, 0 to 9, A to Z, earliest to oldest
  // DESCENDING - DOWN ARROW - largest to smallest, 9 to 0, Z to A, oldest to earliest

  const items = hideCancelledBookings ? bookings.filter((booking) => booking.status !== CANCELLED) : bookings;

  if (sortBy === "bookingDate") {
    return items.sort((a, b) => {
      return sortOrder === "desc" ? b.bookingDate - a.bookingDate : a.bookingDate - b.bookingDate;
    });
  }
  if (sortBy === "cost") {
    return items.sort((a, b) => {
      return sortOrder === "desc" ? b.pricePaid - a.pricePaid : a.pricePaid - b.pricePaid;
    });
  }
  if (sortBy === "startDate") {
    return items.sort((a, b) => {
      const relevantStartDateA = a.startDate;
      const relevantStartDateB = b.startDate;

      return sortOrder === "desc" ? relevantStartDateB - relevantStartDateA : relevantStartDateA - relevantStartDateB;
    });
  }

  return items;
}
