import React from "react";
import BookingCreatePage from "../BookingEditor/BookingCreatePage.js";
import BookingStayListPage from "../BookingStayList/BookingStayListPage.js";
import BookingTravelListPage from "../BookingTravelList/BookingTravelListPage.js";
import TripDetailsPage from "../TripDetails/TripDetailsPage.js";
import BookingDetailsPage from "../BookingDetails/BookingDetailsPage.js";
import AgentBookingsListPage from "../AgentBookingsList/AgentBookingsListPage.js";
import StationBookingsListPage from "../StationBookingsList/StationBookingsListPage.js";
import StayBrandsBookingsListPage from "../StayBrandsBookingsList/StayBrandsBookingsListPage.js";
import CarriersBookingsListPage from "../CarriersBookingsList/CarriersBookingsListPage.js";
import AgentsListPage from "../AgentsList/AgentsListPage.js";
import LoyaltyProgramsListPage from "../LoyaltyProgramsList/LoyaltyProgramsListPage.js";
import StationsListPage from "../StationsList/StationsListPage.js";
import { Routes, Route, Outlet } from "react-router-dom";
import TripsListPage from "../TripsList/TripsListPage.js";
import GlobePage from "../Globe/GlobePage.js";
import CarriersListPage from "../CarriersList/CarriersListPage.js";
import StayBrandsListPage from "../StayBrandsList/StayBrandsListPage.js";
import LoyaltyProgramActivityListPage from "../LoyaltyProgramActivityList/LoyaltyProgramActivityListPage.js";
import FlightsListPage from "../FlightsList/FlightsListPage.js";
import CurrencyListPage from "../CurrencyList/CurrencyListPage.js";
import AIPage from "../AI/AIPage.js";
import AppHeaderBar from "./AppHeaderBar.js";
import PageNotFound from "./PageNotFound.js";
import { styled } from "@mui/material/styles";

const Offset = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
  marginTop: "env(titlebar-area-height, 0px)",
}));

const Layout = () => {
  return (
    <>
      <AppHeaderBar />
      <Offset />
      <Outlet />
    </>
  );
};

export default function Body() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<BookingTravelListPage />} />
        <Route path="flights" element={<FlightsListPage />} />
        <Route path="ai" element={<AIPage />} />
        <Route path="globe" element={<GlobePage />} />
        <Route path="stations" element={<StationsListPage />} />
        <Route path="agents" element={<AgentsListPage />} />
        <Route path="carriers" element={<CarriersListPage />} />
        <Route path="loyalty" element={<LoyaltyProgramsListPage />} />
        <Route path="loyalty/:loyaltyProgramId/:tab?" element={<LoyaltyProgramActivityListPage />} />
        <Route path="carriers/:carrierId/:tab?" element={<CarriersBookingsListPage />} />
        <Route path="hotel-brands" element={<StayBrandsListPage />} />
        <Route path="currency" element={<CurrencyListPage />} />
        <Route path="trips" element={<TripsListPage />} />
        <Route path="hotels" element={<BookingStayListPage />} />
        <Route path="hotels/:stayBrandId" element={<StayBrandsBookingsListPage />} />
        <Route path="bookings/new/:type" element={<BookingCreatePage />} />
        <Route path="bookings/:bookingId" element={<BookingDetailsPage />} />
        <Route path="agents/:agentId" element={<AgentBookingsListPage />} />
        <Route path="stations/:stationId" element={<StationBookingsListPage />} />
        <Route path="trips/:tripId/:tab?" element={<TripDetailsPage />} />
        <Route path="trips/:tripId/new/:type" element={<BookingCreatePage />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
}
