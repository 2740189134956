import ButtonBase from "@mui/material/ButtonBase";
import Link from "@mui/material/Link";
import Skeleton from "@mui/material/Skeleton";
import usePrivacy from "../contexts/Privacy/usePrivacy.js";
import useSnackBar from "../contexts/SnackBar/useSnackBar.js";
import copyToClipboard from "../lib/copyToClipboard.js";
import React from "react";

export default function CopyToClipboardText({ value, sx, enablePrivacy }) {
  const { enqueueSnackbar } = useSnackBar();
  const { privacyModeEnabled } = usePrivacy();

  const copyToClipboardOnClick = () => {
    if (copyToClipboard(value)) {
      enqueueSnackbar({ message: "Copied to Clipboard", variant: "success" });
    }
  };

  const text = (
    <Link onClick={copyToClipboardOnClick} component={ButtonBase} sx={sx} underline="hover" color="text.primary">
      {value}
    </Link>
  );

  if (enablePrivacy !== false && privacyModeEnabled) {
    return <Skeleton animation={false}>{text}</Skeleton>;
  }

  return text;
}
