import Divider from "@mui/material/Divider";
import React from "react";
import { ToggleButtonCompact, ToggleButtonIcon } from "../controls/ToggleButton.js";
import FixedFooterAppBar from "../FixedFooterAppBar.js";
import FlightsList from "../FlightsList/FlightsList.js";
import MainScrollLayout from "../MainScrollLayout.js";
import SubScrollLayout from "../SubScrollLayout.js";
import LoyaltyProgramActivityStaysList from "./LoyaltyProgramActivityStaysList.js";
import { LoyaltyProgramDetailsCardQuery } from "./LoyaltyProgramDetailsCard.js";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import FlightIcon from "@mui/icons-material/Flight";

export default function LoyaltyProgramActivityListPage() {
  const { loyaltyProgramId, tab } = useParams();
  const linkState = { source: "carrier" };

  const tabSafe = tab || "bookings";

  return (
    <>
      <MainScrollLayout footerBar fixed flexColumn>
        <LoyaltyProgramDetailsCardQuery loyaltyProgramId={loyaltyProgramId} />
        <Divider />
        <SubScrollLayout key={tabSafe}>
          {tabSafe === "bookings" && (
            <LoyaltyProgramActivityStaysList loyaltyProgramId={loyaltyProgramId} linkState={linkState} />
          )}
          {tabSafe === "flights" && (
            <FlightsList loyaltyProgramId={loyaltyProgramId} linkState={linkState} showOnlySource />
          )}
        </SubScrollLayout>
      </MainScrollLayout>
      <FixedFooterAppBar>
        <ToggleButtonGroup exclusive value={tabSafe}>
          <ToggleButtonCompact
            color="primary"
            size="small"
            value="bookings"
            component={ReactRouterLink}
            to={`/loyalty/${loyaltyProgramId}`}
          >
            <ToggleButtonIcon>
              <ConfirmationNumberIcon />
            </ToggleButtonIcon>
            Bookings
          </ToggleButtonCompact>
          <ToggleButtonCompact
            color="primary"
            size="small"
            value="flights"
            component={ReactRouterLink}
            to={`/loyalty/${loyaltyProgramId}/flights`}
          >
            <ToggleButtonIcon>
              <FlightIcon />
            </ToggleButtonIcon>
            Travel
          </ToggleButtonCompact>
        </ToggleButtonGroup>
      </FixedFooterAppBar>
    </>
  );
}
