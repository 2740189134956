import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import useTrips from "../../contexts/Trips/useTrips";
import ShortRangeText from "../GenericBookingsTable/ShortRangeText.js";
import { AutoCompleteListBox, AutoCompleteListItem } from "./AutocompleteList.js";

const renderOption = (props, option) => {
  return (
    <AutoCompleteListItem
      {...props}
      sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", ...(props.sx || {}) }}
      key={props.key}
    >
      <Typography
        variant="body2"
        sx={{ flex: "1 1 auto", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
      >
        {option.title}
      </Typography>
      {option.startDate && option.endDate && (
        <Typography
          sx={{ whiteSpace: "nowrap" }}
          variant="body2"
          color="text.disabled"
          paddingLeft={1}
          display="inline-block"
        >
          <ShortRangeText start={option.startDate} end={option.endDate} dense />
        </Typography>
      )}
    </AutoCompleteListItem>
  );
};

export default function TripSelectField({ value, onChange, disabled, required }) {
  const { trips } = useTrips();

  const isEmpty = trips.length === 0;

  const onChangeHandler = (event, newValue) => {
    onChange(newValue);
  };

  if (isEmpty) {
    return (
      <TextField
        required={required}
        label="Trip"
        variant="outlined"
        margin="none"
        size="small"
        autoComplete="off"
        fullWidth
        value=""
        disabled
        SelectProps={{
          MenuProps: {
            MenuListProps: {
              dense: true,
            },
          },
        }}
      />
    );
  }

  return (
    <Autocomplete
      value={value}
      required={required}
      onChange={onChangeHandler}
      autoSelect
      ListboxComponent={AutoCompleteListBox}
      // filterOptions={filterOptions}
      selectOnFocus
      clearOnBlur
      disableClearable={required}
      handleHomeEndKeys
      disabled={disabled}
      options={trips}
      isOptionEqualToValue={(option, value) => {
        return option?.id === value?.id;
      }}
      getOptionLabel={(option) => option?.title || option}
      renderOption={renderOption}
      fullWidth
      slotProps={{
        paper: { elevation: 8 },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          margin="none"
          label="Trip"
          size="small"
          fullWidth
          required={required}
        />
      )}
    />
  );
}
