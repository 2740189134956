import React, { useState, useEffect } from "react";
import useAppInstallation from "../AppInstallation/useAppInstallation.js";
import CurrencyContext from "./CurrencyContext.js";
import currencyFixedData, { generateCurrencyFromRates } from "../fixedData/currency.js";
import Big from "big.js";
import parseISO from "date-fns/parseISO";

const convertRates = ({ rates, input, output, value }) => {
  let inputInEUR;

  const inputFieldValue = value;
  const inputCurrency = input;
  const outputCurrency = output;

  if (isNaN(inputFieldValue)) {
    return undefined;
  }

  if (inputCurrency !== "EUR") {
    const currencyItem = rates.currency.find((item) => item.currency === inputCurrency);
    inputInEUR = parseFloat(inputFieldValue) / currencyItem.rate;
  } else {
    inputInEUR = parseFloat(inputFieldValue);
  }

  if (outputCurrency !== "EUR") {
    const currencyItem = rates.currency.find((item) => item.currency === outputCurrency);

    return new Big(inputInEUR).times(currencyItem.rate).round(2).toNumber();
  }

  return new Big(inputInEUR).round(2).toNumber();
};

export default function CurrencyProvider({ children }) {
  const [currency, setCurrency] = useState(currencyFixedData);
  const [state, setState] = useState({ rates: null, dataLoaded: null });
  const { subscribeToService, recachePreloads } = useAppInstallation();

  const applyDataIfValid = (data) => {
    if (!data?.rates || data.rates.EUR !== 1) return;

    setState({ rates: { ...data, lastModified: parseISO(data.lastModified) }, dataLoaded: new Date() });

    setCurrency(generateCurrencyFromRates(data.rates));
  };

  const loadCurrencyRateData = async () => {
    try {
      const getCurrencyRateDataResponse = await fetch("/api/currency", {
        headers: {
          Accept: "application/json",
        },
      });

      const data = await getCurrencyRateDataResponse.json();
      applyDataIfValid(data);
    } catch (ex) {
      console.log(ex);
    }
    // if (!state.isLoading) {
    //   setState({
    //     ...state,
    //     isLoading: true,
    //   });
    // } else if (!force) {
    //   // isLoading is true by default when in browser
    //   return;
    // }
    // const getPermissionsMatrixResponse = await getPermissionsMatrix();
    // if (!getPermissionsMatrixResponse?.response?.json) return;
    // const response = getPermissionsMatrixResponse?.response?.json;
    //
    // setState({
    //   ...state,
    //   isLoading: false,
    //   isLoaded: true,
    //   isLoadingFailed: false,
    //   groups: response.groups || [],
    //   roles: response.roles || [],
    //   lastChecked: response.lastChecked || null,
    // });
  };

  const handleDataUpdateNotification = ({ data }) => {
    applyDataIfValid(data);
  };

  useEffect(() => {
    const subscriber = subscribeToService("update:currency", handleDataUpdateNotification);

    return () => {
      // App is being unmounted, so stop listening for service worker changes
      subscriber();
    };
  }, []);

  useEffect(() => {
    Promise.allSettled([loadCurrencyRateData()])
      .then(() => {})
      .catch(() => {});
  }, []);

  const convertCurrency = (value, input, output) => {
    if (!state.rates) return null;
    return convertRates({ rates: state.rates, input, output, value });
  };
  const value = { currency, rates: state.rates, convertCurrency, dataLoaded: state.dataLoaded, loadCurrencyRateData };
  return <CurrencyContext.Provider value={value}>{children}</CurrencyContext.Provider>;
}
