import BookingEditorReducerGeneral from "./BookingEditorReducerGeneral.js";
import SegmentEditorReducerValidator from "./SegmentEditorReducerValidator.js";
import emptyTravelObject from "./emptyTravelObject.js";
import emptyStayObject from "./emptyStayObject.js";
import StayEditorReducerValidator from "./StayEditorReducerValidator.js";

export function HandleLoyaltyProgramActivityAction(row, action) {
  if (action.type === "ADD_LOYALTY_PROGRAM_ACTIVITY") {
    return {
      ...row,
      loyaltyProgramActivities: [
        ...(row.loyaltyProgramActivities || []),
        {
          loyaltyProgram: action.value,
          points: "",
          tierPoints: "",
          note: "",
          source: action.value.sources[0],
          isPending: true,
          isCleared: false,
        },
      ],
    };
  } else if (action.type === "DELETE_LOYALTY_PROGRAM_ACTIVITY") {
    return {
      ...row,
      loyaltyProgramActivities: (row.loyaltyProgramActivities || []).filter(
        (loyaltyProgramActivity) => loyaltyProgramActivity.loyaltyProgram.id !== action.value.id,
      ),
    };
  } else if (action.type === "SET_LOYALTY_PROGRAM_ACTIVITY") {
    return {
      ...row,
      loyaltyProgramActivities: (row.loyaltyProgramActivities || []).map((loyaltyProgramActivity) => {
        const { id: idToUpdate, ...other } = action.value;
        if (loyaltyProgramActivity.loyaltyProgram.id !== idToUpdate) return loyaltyProgramActivity;
        return { ...loyaltyProgramActivity, ...other };
      }),
    };
  }
}

export function SegmentEditorReducer(segment, action) {
  if (action.type === "SET_TRIP") {
    return segment;
    // return SegmentEditorReducerValidator({ ...segment, tripId: action.value?.id || undefined });
  }

  if (action.type === "SET_LEADING_CARRIER") {
    return SegmentEditorReducerValidator({
      ...segment,
      isLeadingCarrier: segment.id === action.id && action.value === true,
    });
  }

  if (segment.id !== action.id) return segment;

  const handleLoyaltyProgramActivityActionResult = HandleLoyaltyProgramActivityAction(segment, action);

  if (handleLoyaltyProgramActivityActionResult) {
    return SegmentEditorReducerValidator(handleLoyaltyProgramActivityActionResult);
  }

  return SegmentEditorReducerValidator({
    ...segment,
    ...action.value,
  });
}

export function StayEditorReducer(stay, action) {
  if (action.type === "SET_TRIP") {
    return StayEditorReducerValidator({ ...stay, tripId: action.value?.id || undefined });
  }

  if (stay.id !== action.id) return stay;

  const handleLoyaltyProgramActivityActionResult = HandleLoyaltyProgramActivityAction(stay, action);

  if (handleLoyaltyProgramActivityActionResult) {
    return StayEditorReducerValidator(handleLoyaltyProgramActivityActionResult);
  }

  return StayEditorReducerValidator({
    ...stay,
    ...action.value,
  });
}

export default function BookingEditorReducer(state, action) {
  if (!action) return state;
  const { type, value } = action;
  let nextState = state;

  const SegmentReducerMap = (segment) => SegmentEditorReducer(segment, action);
  const StayReducerMap = (segment) => StayEditorReducer(segment, action);

  if (type === "DELETE_SEGMENT") {
    nextState = BookingEditorReducerGeneral({
      ...state,
      segments: state.segments.filter((segment) => segment.id !== action.id),
    });
    nextState.segmentsToDelete = [...(nextState.segmentsToDelete || []), action.id];
    if (nextState.segments.length && !nextState.segments.find((segment) => segment.isLeadingCarrier)) {
      nextState.segments[0] = { ...nextState.segments[0], isLeadingCarrier: true };
    }
  } else if (type === "DELETE_STAY") {
    nextState = BookingEditorReducerGeneral({
      ...state,
      stays: state.stays.filter((stay) => stay.id !== action.id),
    });
    nextState.staysToDelete = [...(nextState.staysToDelete || []), action.id];
  } else if (type === "SET_STATE") {
    nextState = BookingEditorReducerGeneral({ ...state, ...value });
  } else if (type === "SET_TRIP") {
    nextState = BookingEditorReducerGeneral({
      ...state,
      trip: value,
      segments: state.segments.map(SegmentReducerMap),
      stays: state.stays.map(StayReducerMap),
    });
  } else if (type === "SET_SEGMENT" || type === "SET_LEADING_CARRIER") {
    nextState = BookingEditorReducerGeneral({ ...state, segments: state.segments.map(SegmentReducerMap) });
  } else if (
    type === "ADD_LOYALTY_PROGRAM_ACTIVITY" ||
    type === "SET_LOYALTY_PROGRAM_ACTIVITY" ||
    type === "DELETE_LOYALTY_PROGRAM_ACTIVITY"
  ) {
    nextState = BookingEditorReducerGeneral({
      ...state,
      segments: state.segments?.map(SegmentReducerMap),
      stays: state.stays?.map(StayReducerMap),
    });
  } else if (type === "SET_STAY") {
    nextState = BookingEditorReducerGeneral({ ...state, stays: state.stays.map(StayReducerMap) });
  } else if (type === "ADD_SEGMENT") {
    const hasNoSegments = state.segments.length === 0;
    const lastSegment = hasNoSegments ? null : state.segments[state.segments.length - 1];
    nextState = BookingEditorReducerGeneral({
      ...state,
      segments: [
        ...state.segments,
        emptyTravelObject({
          bookingId: state.id,
          isLeadingCarrier: hasNoSegments,
          carrier: lastSegment?.carrier,
          origin: lastSegment?.destination,
          departureDate: lastSegment?.departureDate,
          originTitle: (lastSegment?.destination?.id ? "" : lastSegment?.destinationTitle) || "",
        }),
      ],
    });
  } else if (type === "ADD_STAY") {
    nextState = BookingEditorReducerGeneral({
      ...state,
      stays: [...state.stays, emptyStayObject({ bookingId: state.id })],
    });
  }

  return nextState;
}
