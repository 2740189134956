import React, { useEffect, useReducer } from "react";
import TripDetailsLayout from "./TripDetailsLayout.js";
import TripDetailsMangerInitialState from "./TripDetailsMangerInitialState.js";
import TripDetailsManagerReducer from "./TripDetailsManagerReducer.js";

export default function TripDetailsManager({ trip, bookings, tab }) {
  const [state, dispatch] = useReducer(TripDetailsManagerReducer, { trip, bookings }, TripDetailsMangerInitialState);

  const setState = (value) => dispatch({ type: "SET_STATE", value });

  useEffect(() => {
    setState({ trip });
  }, [trip]);

  useEffect(() => {
    dispatch({ type: "SET_BOOKINGS", value: bookings });
  }, [bookings]);

  return <TripDetailsLayout state={state} tab={tab} setState={setState} dispatch={dispatch} />;
}
