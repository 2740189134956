import bookingsOrderedByDate, { bookingsOrderedByOptions } from "./bookingsOrderedByDate.js";
import groupBookingsByLoyaltyProgram from "./groupBookingsByLoyaltyProgram.js";

const sortBy = "startDate";
// desc = down arrow (z to a, high to low, latest (new) to earliest, (old),
// asc = up arrow (a to z, low to high, earliest to latest)
const sortOrder = "asc";

const hideCancelledBookings = true;

export default function TripDetailsMangerInitialState({ trip, bookings }) {
  return {
    trip,
    bookings,
    // Bookings Table
    bookingsOrderedByOptions: bookingsOrderedByOptions({
      bookings,
      sortBy,
      sortOrder,
      hideCancelledBookings,
    }),
    // Loyalty Table
    groupBookingsByLoyaltyProgram: groupBookingsByLoyaltyProgram({ bookings }),
    // Itinerary Table
    bookingsOrderedByDate: bookingsOrderedByDate({ bookings }),
    editModalIsOpen: false,
    sortBy,
    sortOrder,
    hideCancelledBookings,
  };
}
