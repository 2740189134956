import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import Chip from "@mui/material/Chip";
import React from "react";
import { BOOKED, CANCELLED, REFUNDED } from "../../models/BookingConstants.js";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import UndoIcon from "@mui/icons-material/Undo";
export default function BookingPaymentStatusChip({ booking }) {
  const { isPaid, status } = booking;

  if (isPaid && status === CANCELLED) {
    return <Chip icon={<CheckCircleIcon />} color="error" label="Paid" size="small" variant="outlined" />;
  }

  if (isPaid && status === REFUNDED) {
    return <Chip icon={<CheckCircleIcon />} color="info" label="Refunded" size="small" variant="outlined" />;
  }

  if (isPaid && status === BOOKED) {
    return <Chip icon={<CheckCircleIcon />} color="success" label="Paid" size="small" variant="outlined" />;
  }

  if (!isPaid && status === BOOKED) {
    return <Chip icon={<ErrorIcon />} color="warning" label="Unpaid" size="small" variant="outlined" />;
  }

  if (!isPaid && status === CANCELLED) {
    return <Chip icon={<HighlightOffIcon />} color="default" label="Cancelled" size="small" variant="outlined" />;
  }

  if (!isPaid && status === REFUNDED) {
    return <Chip icon={<UndoIcon />} color="info" label="Refunded" size="small" variant="outlined" />;
  }

  return null;
}
