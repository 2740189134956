import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import Chip from "@mui/material/Chip";
import React from "react";
import { isPast } from "date-fns";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function LoyaltyProgramActivityStatusChip({ loyaltyProgramActivity }) {
  if (!loyaltyProgramActivity) return null;

  const { isCleared, isPending, clearDueDate } = loyaltyProgramActivity;

  if (isCleared === true) {
    return (
      <Chip
        icon={<CheckCircleIcon fontSize="small" />}
        color="success"
        label="Cleared"
        size="small"
        variant="outlined"
      />
    );
  }

  if (isPending === true) {
    if (clearDueDate && isPast(clearDueDate)) {
      return <Chip icon={<ErrorIcon />} color="error" label="Overdue" size="small" variant="outlined" />;
    }

    return (
      <Chip icon={<HourglassTopIcon fontSize="small" />} color="info" label="Pending" size="small" variant="outlined" />
    );
  }

  if (clearDueDate && isPast(clearDueDate)) {
    return <Chip icon={<ErrorIcon />} color="error" label="Overdue" size="small" variant="outlined" />;
  }

  return (
    <Chip icon={<HelpOutlineIcon fontSize="small" />} color="default" label="Unknown" size="small" variant="outlined" />
  );
}
