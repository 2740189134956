import React, { useState, useEffect } from "react";
import PrivacyContext from "./PrivacyContext.js";

export default function PrivacyProvider({ children }) {
  const [privacyModeEnabled, setPrivacyModeEnabled] = useState(false);

  return (
    <PrivacyContext.Provider value={{ privacyModeEnabled, setPrivacyModeEnabled }}>{children}</PrivacyContext.Provider>
  );
}
