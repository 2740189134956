import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import React from "react";
import { useIntl } from "react-intl";
import useCurrency from "../../contexts/Currency/useCurrency.js";

export default function CurrencyListSummaryHeader() {
  const { rates, dataLoaded } = useCurrency();

  const isLoaded = Boolean(rates?.lastModified);
  const { formatDate } = useIntl();

  return (
    <>
      <CardContent>
        <Typography variant="body2">
          {isLoaded ? (
            `European Central Bank rates as of: ${formatDate(rates.lastModified, {
              year: "numeric",
              month: "short",
              day: "numeric",
              weekday: "short",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
              timeZone: "CET",
              timeZoneName: "short",
            })}`
          ) : (
            <Skeleton variant="body2">European Central Bank rates as of: Mon, 9 Dec 2024, 5:26 am</Skeleton>
          )}
        </Typography>
        <Typography variant="body2">
          {dataLoaded ? (
            `Last retrieved data: ${formatDate(dataLoaded, {
              year: "numeric",
              month: "short",
              day: "numeric",
              weekday: "short",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}`
          ) : (
            <Skeleton variant="body2">Last retrieved data: Mon, 9 Dec 2024, 5:26 am</Skeleton>
          )}
        </Typography>
      </CardContent>
      <Divider />
    </>
  );
}
